<template>
    <Hero>
      <h1>
          Impressum
      </h1>
    </Hero>
    <section>
      <section-spacer :spacer="2" type="gap"></section-spacer>
      <div class="sp"></div>
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-9 col-xl-8">
            <h2 class="h3">
              Angaben gemäß §5 TMG:
            </h2>
            <address>
              Paul Zimmermann<br>
              Gertrud-Bäumer-Straße 9<br>
              44534 Lünen<br><br>
              E-Mail: <a href="mailto:contact@milkycheck.com" target="_blank">
                contact@milkycheck.com
              </a>
            </address>
            <div class="sp l"></div>
            <h2 class="h3">
              Verantwortlicher im Sinne von § 18 Abs. 2 MStV:
            </h2>
            <p>
              Paul Zimmermann<br>
              (Anschrift wie oben)
            </p>
            <div class="sp l"></div>
            <p>
              Bitte haben Sie Verständnis dafür,
              dass wir keine individuelle Beratung per E-Mail anbieten können.
            </p>
          </div>
        </div>
      </div>
      <div class="sp"></div>
    </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Hero from '@/components/Hero.vue';
import SectionSpacer from '@/components/SectionSpacer.vue';

export default defineComponent({
  name: 'Imprint',

  components: {
    Hero,
    SectionSpacer,
  },
});
</script>

<style scoped>

</style>
